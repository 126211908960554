.Content{
    /* margin-top: 65px; */
    text-align: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    /* transition: transform 0.3s ease-out; */
    /* animation-name: load-in;
    animation-duration: 3s; */
}

/* .LoadingScreen {
    text-align: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    z-index: 200;
    animation-name: load-out;
    animation-duration: 3s;
 } */

.Disclaimer {
    text-align: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    color: green;
    height: 100%;
    /* background: linear-gradient(to right, rgb(0, 75, 0) , black,  rgb(0, 75, 0)); */
    padding-top: 9%;
    box-sizing: border-box;
    box-shadow: green;
    overflow: auto;
}

.headline{
    font-family: 'G7 Silkworm TTF';
}

.visuals{
    text-align: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    
}

@keyframes load-out {
    from {transform:translateX(0)}
    to {transform: translateX(-100%);}
    
  }

@keyframes load-in {
from {transform:translateX(100%)}
to {transform: translateX(0);}

}


.texting{
    white-space: pre-line;
    width: 30%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    color: green;
    padding: 20px 10px;
}

.titles {
    font-family: 'G7 Silkworm TTF'
}

.blinking{
    animation:blinkingText 1.2s infinite;
    cursor: pointer;
}

@keyframes blinkingText{
    0%{     color: green;    }
    49%{    color: green; }
    60%{    color: transparent; }
    99%{    color:transparent;  }
    100%{   color: green;    }
}

@media (max-width: 499px) {
    .texting{
        width: 70%;
        padding: 20px 4px;
    }

}
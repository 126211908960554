.FreeRun {
    color: green;
    height: 100%;
    /* background: linear-gradient(to right, rgb(0, 75, 0) , black,  rgb(0, 75, 0)); */
    padding-top: 75px;
    box-sizing: border-box;
    box-shadow: green;
    overflow: auto;

}

.FormStyle {
    width: 50%;
    /* background-color: rgb(15, 15, 15); */
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    color: green;
    padding: 20px 10px; 
}

.titles {
    font-family: 'G7 Silkworm TTF'
}

.Note {
    font-size: small;
    font-style: italic;
}

.smallText{
    color: #696969;
    font-family: Arial, Helvetica, sans-serif;
}

@media (max-width: 499px) {
    .FreeRun {
        color: green;
        height: 100%;
        width: 100%;
        padding-top: 14%;
    }

    .FormStyle {
        width: 70%;
    }
}

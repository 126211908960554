.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    color: green;
    /* height: 100%; */
    width: 100%;
    /* align-items: center; */
}

.NavigationItem a{
    color:green;
    text-decoration: none;
    width: 100%;
    /* padding: 16px 10px; */
    /* border-bottom: 4px solid transparent; */
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    /* background-color: #8F5C2C; */
    /* border-bottom: 4px solid #40A4C8; */
    color: #40A4C8;
}

@media (min-width:499px){
    .NavigationItem {
        margin: 0;
        /* box-sizing: border-box; */
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavigationItem a{
        color: green;
        /* text-decoration: none; */
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
        /* box-sizing: border-box; */
        /* display: block; */
    }
    
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        background-color: darkgreen;
        border-bottom: 4px solid green;
        color: white;
    }
}
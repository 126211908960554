.ExperienceMenu{
    overflow: auto;
    color: black;
    height:100%;
    background-image: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
    height:100%;
    text-align: left;
}

.Experiences {
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

// .centered {
//     cursor: pointer;
//     display: inline-block;
//     padding: 15px 20px;
//     position: relative;
//   }
// .centered:after {    
//     background: none repeat scroll 0 0 transparent;
//     bottom: 0;
//     content: "";
//     display: block;
//     height: 2px;
//     left: 50%;
//     position: absolute;
//     background: whitesmoke;
//     transition: width 0.3s ease 0s, left 0.3s ease 0s, height 0.4s ease 0s ;
//     width: 0;
// }
// .centered:hover:after { 
//     width: 100%; 
//     left: 0; 
// }

.Title{
    text-align: center;
    align-items: center;
    font-size: 45px;
    // margin-top: 100px;
}

.ReturnMenu {
  padding-left: 70px;
  // margin:10px;
}

@mixin magic-border($width, $color, $duration, $direction){
  position:relative;
    &:before{
      content:'';
      position:absolute;
      width:calc(100% + #{$width * 2});
      height:calc(100% + #{$width * 2});
      top:calc(#{$width}/-1);
      left:calc(#{$width}/-1);
      background:linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to left, $color 0%, $color 100%);
      background-size:100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
      background-position:50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
      background-repeat:no-repeat, no-repeat;
      transition:transform $duration ease-in-out, background-position $duration ease-in-out, background-size $duration ease-in-out;
      transform:scaleX(0) rotate(180deg * $direction);
      transition-delay:$duration*2, $duration, 0s;
    }
    &:hover{
      &:before{
        background-size:200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
        background-position:50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
        transform:scaleX(1) rotate(180deg * $direction);
        transition-delay:0s, $duration, $duration*2;
      }
    }
}


// body{
//   display:flex;
//   flex-wrap:wrap;
//   justify-content:center;
//   align-items:center;
//   height:100vh;
  .centered{
    
    cursor: pointer;
    // display: inline-block;
    padding: 15px 20px;
    justify-content:space-between;
    width:300px;
    // text-decoration:none;
    color: black; 
    @include magic-border(2px, black, 0.3s, 0);
    margin:10px;
    flex-grow:1;
    
    
  }
// }

@media (max-width: 699px) {

  .Title{
    font-size: 24px;
}

.ReturnMenu {
  padding-left: 80px;
  // margin:10px;
}

}
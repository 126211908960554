.Visual {
    /* padding-top: 7%; */
    padding-top: 20px;
    height: 100%;
    overflow: auto;
}



.FormStyle {
    width: 65%;
    color: green;
    border: solid;
    margin: auto;
    text-align: center;
    overflow: auto;
}

.column {
    float: left;
    width: 100%;
    padding: 10px;
    overflow: auto;
    /* height: 300px; Should be removed. Only for demonstration */
  }

  .Note {
    color: green;
    font-size: small;
    font-style: italic;
}

.returnText {
    font-size: 15px;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    flex-flow: column;
    font-weight:lighter;
    color: darkgrey;
    cursor: pointer;
    background-color:rgba(95,95,95, 0.4)
  }







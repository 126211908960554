.ButtonPadding{
    padding-top: 55px;
    font-size: 20px;
    
}

.Grouping{
    font-size: 35px;
    font-family: Arial;
    background: transparent;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 997px;
    text-align: center;
    overflow: auto;
}

@media (max-width: 499px) {
    .Grouping{
        overflow: auto;
        width: 100%;
    }
}
.Toolbar {
    height: 50px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #152238;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 20px; */
    padding-left: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
    z-index: 90;        
   
}


.Toolbar nav {
    height: 100%;
}

.Logo {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
    height: 80%;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } } 
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@media (max-width: 499px){
    .DesktopOnly {
        display: none;  
    }
    .Logo{
        display: none;
    }
}
.MantraChamber {
    padding-top: 20%;
    height: 100%;
    animation: disappear 5s infinite;
    font-family: 'G7 Silkworm TTF';
}

.BackgroundImage{
    background-image: url('../../../assets/Images/mantra.png');
    height: 100%;
    padding-top: 75px;
    box-sizing: border-box;
    box-shadow: green;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
    opacity: 0.2;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } } 
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@keyframes disappear {
    0% {
        opacity: 1;
    }
    60% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}

@media (max-width: 499px) {

    .MantraChamber {
        padding-top: 35%;
    }
}
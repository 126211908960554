.Homepage {
    color: green;
    height: 100%;
    // background: linear-gradient(to right, rgb(0, 75, 0) , black,  rgb(0, 75, 0));
    padding-top: 7%;
    box-sizing: border-box;
    box-shadow: green;
    overflow: auto;
}

.headline{
    font-family: 'G7 Silkworm TTF';
}
.texting{
    font-family: 'G7 Silkworm TTF';
    width: 70%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    color: green;
    padding: 20px 10px;
}

.YouTube{
    width: 50%; 
    height: 316px;
}

.linking {
    box-sizing: border-box;
    /* justify-content: space-between; */
    align-items: center;
    /* box-shadow: 0 2px 1px; */
    margin: auto;
    color: green;
    padding: 20px 10px;
}

.Footer {
    width: 100%;
    background-color: #152238;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    color: green;
    padding: 20px 10px;
    border-top: 1px solid #152238;
    border-bottom: 1px solid #152238;
}


@media (max-width: 499px) {
    .Homepage {
        padding-top: 14%;
    }

    .YouTube{
        width: 70%; 
        height: 316px;
    }

    
}

// @media (max-height: 899px){
//     .Footer {
//         // position: relative;
//         height: 8%;
//     }
// }
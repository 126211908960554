.Account {
    color: green;
    height: 100%;
    /* background: linear-gradient(to right, rgb(0, 75, 0) , black,  rgb(0, 75, 0)); */
    padding-top: 75px;
    box-sizing: border-box;
    
    box-shadow: green;
    overflow: auto;

}

.AccountContainer {
    width: 50%;
    margin: auto;
}

.FormStyle {
    width: 70%;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    color: green;
    padding: 15px 10px;
}

.ViewProfile {
    width: 70%;
    box-sizing: border-box;
    margin: auto;
    display: inline-block;
    flex-flow: column;
    align-items: center;
    color: green;
    padding: 15px 10px;
}

.Note {
    font-size: small;
    font-style: italic;
}

.AuthButtons{
    padding: 15px 10px;
}

.viewHostRunDetails{
    align-items: stretch;
    /* display: block; */
}

.smallText{
    color: #696969;
    font-family: Arial, Helvetica, sans-serif;
}

.PasswordLink{
    color: green;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.SpecialLink{
    color: green;
}


.error{
    color: red;
    font-style: oblique;
}

@media (max-width: 900px) {
    .Account {
        width: 100%;
        padding-top: 14%;
    }
    .FormStyle {
        width: 100%;
        padding: 20px 10px;
    }

    .AccountContainer {
        width: 100%;
    }

    .Spinner {
        padding-top: 14%;
    }

}
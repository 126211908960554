Button {
    /* background-color: #5C9210; */
    /* border: black; */
    /* color: black; */
    /* outline: groove; */
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
}

.InputElement {
    width: 70%;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    /* display: block; */
}

.InputElement:focus {
    outline: none;
    background-color: #ccc;
}

.Note {
    font-size: small;
    font-style: italic;
}

.Grouping{
    font-size: 24px;
    font-family: Arial;
    background: transparent;
    text-align: center;
    overflow: auto;
    white-space: pre-line;
    /* width: 80%; */
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    color: green;
    padding: 20px 10px;
}

.texting{
    white-space: pre-line;
    width: 30%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    color: green;
    padding: 20px 10px;
}

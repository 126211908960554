.Button {
    background-color: transparent;
    border: black;
    color: grey;
    outline: groove;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
}

/* .Button:first-of-type {
    margin-left: 0;
    padding-left: 0;
} */

.Button:disabled{
    color: #ccc;
    background-color: grey;
    cursor: not-allowed;
}

.Button:not(:disabled) {
    animation: enable 0.3s linear;
}

@keyframes enable {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.Success {
    color: white;
    background-color: #5C9210;
}

.Danger {
    color:white;
    background-color: #944317;
}


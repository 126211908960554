.FreeRun {
    color: green;
    height: 100%;
    padding-top: 75px;
    box-sizing: border-box;
    box-shadow: green;
    overflow: auto;

}

.FormStyle {
    width: 70%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    color: green;
    padding: 20px 10px;
}

.smallText{
    color: #696969;
    font-family: Arial, Helvetica, sans-serif;
}


.BellChoice {
    padding-top: 5%;
    height: 100%;
    animation: disappear 5s infinite;
    font-family: 'G7 Silkworm TTF';
}

.MetronomeChoice {
    padding-top: 5%;
    height: 100%;
    animation: disappear 3.4s infinite;
    font-family: 'G7 Silkworm TTF';
}

.TickingClockChoice {
    padding-top: 5%;
    height: 100%;
    animation: disappear 4s infinite;
    font-family: 'G7 Silkworm TTF';
}

.MantraChoice {
    padding-top: 5%;
    height: 100%;
    animation: disappear 5s infinite;
    font-family: 'G7 Silkworm TTF';
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } } 
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@keyframes disappear {
    0% {
        opacity: 1;
    }
    60% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}
html {    
     
  height: 100%;
  background-image: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
  // background: url('../../../assets/Images/Half.png') no-repeat;  
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.greatOSpeech {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
}

.ChapterTitle{
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 50vh;
  bottom: 300px;
  font-size: 50px;
}

.Experiences {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 30vh;
  bottom: 100px;
}

.ChapterSelect {
  cursor: pointer;
  display: flex;
  font-size: 28px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 60vh;
  bottom: 150px;
  
}

.buttonSize {
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    cursor: pointer;
    display: inline-block;
    // padding: 15px 20px;
    position: relative;
}

.buttonSize:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: black;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.buttonSize:hover:after { 
  width: 100%; 
  left: 0; 
}
@media screen and (max-height: 300px) {
  ul {
      margin-top: 40px;
  }
}

.storyButtonSize {
  font-size: 20px;
}


// @media (max-height: 850px) {

//   .Experiences {
//     justify-content: space-evenly;
//     align-items: center;
//     text-align: center;
//     position: absolute;
//     width: 100%;
//     bottom: 150px;
//   }

//   .buttonSize {
//     font-size: 15px;
//   }

//   .centered {
//     // text-decoration:none;
//     display: list-item;
//     color: black; 
//   }

//   .storyFrom {
    
//     width: 50%;
//     height: 65%;
//     font-size: 15px;
//     bottom: 100px;
//     left: 10%
//   }

// }


@mixin magic-border($width, $color, $duration, $direction){
  position:relative;
    &:before{
      content:'';
      position:absolute;
      width:calc(100% + #{$width * 2});
      height:calc(100% + #{$width * 2});
      top:calc(#{$width}/-1);
      left:calc(#{$width}/-1);
      background:linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to left, $color 0%, $color 100%);
      background-size:100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
      background-position:50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
      background-repeat:no-repeat, no-repeat;
      transition:transform $duration ease-in-out, background-position $duration ease-in-out, background-size $duration ease-in-out;
      transform:scaleX(0) rotate(180deg * $direction);
      transition-delay:$duration*2, $duration, 0s;
    }
    &:hover{
      &:before{
        background-size:200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
        background-position:50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
        transform:scaleX(1) rotate(180deg * $direction);
        transition-delay:0s, $duration, $duration*2;
      }
    }
}


// body{
//   display:flex;
//   flex-wrap:wrap;
//   justify-content:center;
//   align-items:center;
//   height:100vh;
  .centered{
    
    cursor: pointer;
    display: inline-block;
    padding: 20px 20px;
    justify-content:space-between;
    width:95px;
    // text-decoration:none;
    color: black; 
    @include magic-border(2px, black, 0.3s, 0);
    margin:15px;
    flex-grow:1;
  }
// }

.storyFrom {
  overflow: auto;
  font-family: "Times New Roman", Times, serif;
  scroll-behavior: smooth;
  display: inline-block;
  height: 70vh;
  width: 30vw;
  font-size: 20px;
  // justify-content: space-evenly;
  align-items: center;
  text-align: center;
  position: fixed;
  bottom: 150px;
  left: 35%
}

// .storyFrom {
//   height: 70%;
//   width: 100px;
//   display: flex;
//   position: fixed;
//   align-items: center;
//   text-align: center;
//   justify-content: center;
//   bottom: 150px;
// }
//Iphone X
// @media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3){
//       .Experiences {
//             height: 25vh;
//             bottom: 200px;
//           }
        
//           .ChapterTitle{
//             bottom: 200px;
//             font-size: 25px;
//           }
        
//           .ChapterSelect {
//             width: 100vw;
//             height: 50vh;
//             bottom: 150px;
            
//           }
        
//           .storyFrom {
//             height: 55vh;
//             width: 60vw;
//             left: 20%;
//             bottom: 180px;
//           }
          
//           .buttonSize {
//             font-size: 17px;
//           }
//     }
// //IPhone 11 Pro Max
// @media only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3){
//   .Experiences {
//         height: 27vh;
//         bottom: 200px;
//       }
    
//       .ChapterTitle{
//         bottom: 200px;
//         font-size: 25px;
//       }
    
//       .ChapterSelect {
//         width: 100vw;
//         height: 50vh;
//         bottom: 150px;
        
//       }
    
//       .storyFrom {
//         height: 55vh;
//         width: 60vw;
//         left: 20%;
//         bottom: 180px;
//       }
      
//       .buttonSize {
//         font-size: 17px;
//       }
// }
// //Samsung S20 Ultra
// @media only screen and (device-width : 412px) and (device-height : 915px) and (-webkit-device-pixel-ratio : 3){
//   .Experiences {
//         height: 27vh;
//         bottom: 200px;
//       }
    
//       .ChapterTitle{
//         bottom: 200px;
//         font-size: 25px;
//       }
    
//       .ChapterSelect {
//         width: 100vw;
//         height: 50vh;
//         bottom: 150px;
        
//       }
    
//       .storyFrom {
//         height: 55vh;
//         width: 60vw;
//         left: 20%;
//         bottom: 180px;
//       }
      
//       .buttonSize {
//         font-size: 17px;
//       }
// }

@media only screen and (max-width: 299px) {

  .Experiences {
    width: 100vw;
    height: 35vh;
    bottom: 100px;
  }

  .ChapterTitle{
    bottom: 100px;
    font-size: 20px;
  }

  .ChapterSelect {
    width: 100vw;
    height: 40vh;
    bottom: 150px;
    
  }

  .storyFrom {
    height: 38vh;
    width: 57vw;
    left: 20%;
    bottom: 250px;
  }

  
  .buttonSize {
    font-size: 10px;
  }

  .storyButtonSize {
    font-size: 15px;
  }

}

@media only screen and (min-width: 300px) and (max-width: 399px) {
  .Experiences {
    width: 100vw;
    height: 33vh;
    bottom: 100px;
  }

  .ChapterTitle{
    bottom: 150px;
    font-size: 22px;
  }

  .ChapterSelect {
    width: 100vw;
    height: 43vh;
    bottom: 150px;
    
  }

  .storyFrom {
    height: 45vh;
    width: 60vw;
    left: 20%;
    bottom: 220px;
  }

  .buttonSize {
    font-size: 12px;
  }

  .storyButtonSize {
    font-size: 15px;
  }
}



@media only screen and (min-width: 400px) and (max-width: 499px) {

  .Experiences {
    width: 100vw;
    height: 35vh;
    bottom: 100px;
  }

  .ChapterTitle{
    bottom: 200px;
    font-size: 25px;
  }

  .ChapterSelect {
    width: 100vw;
    height: 50vh;
    bottom: 150px;
    
  }

  .storyFrom {
    height: 56vh;
    width: 66vw;
    left: 18%;
    bottom: 180px;
  }

  
  .buttonSize {
    font-size: 15px;
  }

  .storyButtonSize {
    font-size: 17px;
  }

}

@media only screen and (min-width: 500px) and (max-width: 699px) {
  
  .Experiences {
    width: 100vw;
    height: 35vh;
    bottom: 100px;
  }

  .buttonSize {
    font-size: 18px;
  }

  .ChapterTitle{
    bottom: 220px;
    font-size: 30px;
  }

  .ChapterSelect {
    width: 100vw;
    height: 53vh;
    bottom: 150px;
    
  }

  .storyFrom {
    height: 70vh;
    width: 57vw;
    left: 20%;
    bottom: 150px;
  }

  .storyButtonSize {
    font-size: 18px;
  }

}

// @media only screen and (min-width: 700px) and (max-width: 1000px) {

//   .ChapterTitle{
//     bottom: 220px;
//     font-size: 30px;
//   }

//   .ChapterSelect {
//     width: 100vw;
//     height: 55vh;
//     bottom: 150px;
    
//   }

//   .buttonSize {
//     font-size: 18px;
//   }
// }

// @media (max-height: 1000px) {

//   .ChapterTitle{
//     bottom: 220px;
//     font-size: 30px;
//   }
// }
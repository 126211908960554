.HostsChamber {
    color: green;
    height: 100%;
    /* background: linear-gradient(to right, rgb(0, 75, 0) , black,  rgb(0, 75, 0)); */
    padding-top: 7%;
    box-sizing: border-box;
    box-shadow: green;
    overflow: auto;

}

.FormStyle {
    white-space: pre-line;
    /* width: 80%; */
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    color: green;
    padding: 20px 10px;
}


.smallText{
    color: #696969;
    font-family: Arial, Helvetica, sans-serif;
}

.textCommand{
    width: 80%;
    
}

.titles {
    font-family: 'G7 Silkworm TTF'
}

.error{
    
    color: red;
    font-style: oblique;
}

.Note {
    font-size: small;
    font-style: italic;
}

@media (max-width: 499px) {
    .HostsChamber {
        width: 100%;
        padding-top: 14%;
    }

    .FormStyle {
        width: 70%;
    }

    .Spinner {
        padding-top: 14%;
    }

    .HostChamberButton {
        background-color: green;
        outline: none;
        cursor: pointer;
        border: 1px solid darkgreen;
        color: black;
        font-family: inherit;
        font-size: 1.2em;
        padding: 15px 30px;
        box-shadow: 2px 2px 2px darkgreen;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .HostChamberButton:hover, .HostChamberButton:active {
        background-color: darkgreen;
        border: 1px solid green;
        color: white;
    }
}
.headline{
    font-family: 'G7 Silkworm TTF';
    color: green;
}

.images{
    width: 200px;
    height: 200px;
}

.visuals{
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    align-items: center;
    /* box-shadow: 0 2px 1px; */
    margin: auto;
    color: green;
    padding: 20px 10px;
    overflow: auto;
}

.centered {
    color: green;
    font-family: 'G7 Silkworm TTF';
    overflow: auto;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    cursor: pointer;
    display: inline-block;
    padding: 15px 20px;
    position: relative;
  }
.centered:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: green;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}
.centered:hover:after { 
    width: 100%; 
    left: 0; 
}
@media screen and (max-height: 300px) {
    ul {
        margin-top: 40px;
    }
}

/* .rows{
    text-align: center;
}
.container {
    text-align: center;
    padding: 20px;
    display: table-cell;
    color: white;
    
    overflow: auto;
} */

@media (max-width: 499px) {

    /* .visuals{
        width: 95%;
        display: inline-block;
    } */

}



